import React from 'react';
import styled from 'styled-components';

const StyledContent = styled.div`
  @media screen and (min-width: 680px) {
    column-gap: 1.5em;
    grid-row: 2;
    columns: 2 366px;
    grid-column: 1;
    margin-right: 2.5em;
  }

  @media screen and (min-width: 769px) {
    grid-column: 2;
  }

  p {
    margin: 0 0 1.5em;
    line-height: 1.75;
    color: var(--color-body-text);
  }
`;

const Content: React.FC = ({ children }) => <StyledContent>{children}</StyledContent>;

export default Content;
