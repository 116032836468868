import React from 'react';
import textCleanup from '$utils/textCleanup';
import { WrapMax } from '$components/Wraps';
import Mark from '$components/Mark';
import Heading1 from '$components/Heading1';
import FadeInSection from '$components/FadeInSection';
import { Color } from '$utils/Color';
import Title from './Title';
import Tags from './Tags';
import Scroller from './Scroller';
import Content from './Content';
import ExcerptWrap from './ExcerptWrap';
import { BlockCaseHeroProps } from './types';

const BlockCaseHero: React.FC<BlockCaseHeroProps> = ({
  h1,
  h1Highlight,
  h1AfterHighlight,
  h2,
  contentColumn1,
  contentColumn2,
  tags,
}) => (
  <div>
    <FadeInSection>
      <WrapMax>
        <Heading1 primary caseHero>
          {textCleanup(h1)} <Mark color={Color.citrus4}>{textCleanup(h1Highlight)}</Mark>{' '}
          {textCleanup(h1AfterHighlight)}
        </Heading1>
      </WrapMax>
    </FadeInSection>
    <ExcerptWrap>
      <Scroller />
      <Title>{h2}</Title>
      <Content>
        <p>{contentColumn1}</p>
        <p>{contentColumn2}</p>
      </Content>
      <Tags tags={tags} />
    </ExcerptWrap>
  </div>
);

export default BlockCaseHero;
