import React from 'react';
import styled from 'styled-components';

const StyledTitle = styled.span`
  display: flex;
  font-family: var(--font-osynlig);
  font-feature-settings: 'ss01' on;
  font-weight: normal;
  font-size: 1.5em;
  line-height: 2rem;
  position: relative;
  color: var(--color-slate);

  @media screen and (min-width: 680px) {
    grid-row: 1;
    grid-column: 1 / 3;
  }

  @media screen and (min-width: 769px) {
    grid-column: 2 / 4;
  }

  &:after {
    grid-row: 2;
    margin-left: auto;
    color: var(--color-citrus);
    content: '—';
    display: none;

    @media screen and (min-width: 680px) {
      display: block;
    }
  }
`;

const Title: React.FC = ({ children }) => <StyledTitle>{children}</StyledTitle>;

export default Title;
