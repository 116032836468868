import React from 'react';
import styled from 'styled-components';
import { TagsProps } from './types';

const StyledTags = styled.ul`
  grid-row: 1;
  list-style: none;
  margin: 0;
  padding: 0;

  @media screen and (min-width: 680px) {
    grid-row: 2;
    text-align: right;
  }

  &:before {
    font-family: var(--font-osynlig);
    font-feature-settings: 'ss01' on;
    font-weight: normal;
    font-size: 1.5em;
    line-height: 2rem;
    color: var(--color-citrus);
    content: '—';
    display: block;
    margin-bottom: 0.5rem;

    @media screen and (min-width: 680px) {
      display: none;
    }
  }
`;

const StyledTag = styled.li`
  font-size: 0.6875em;
  line-height: 2em;
  font-family: var(--font-sans);
  margin-bottom: 0.75rem;
  text-transform: uppercase;
  font-weight: bold;
  display: inline-flex;
  color: var(--color-slate);

  @media screen and (min-width: 680px) {
    display: block;
  }

  &:after {
    content: '·';
    margin: 0 0.75em;

    @media screen and (min-width: 680px) {
      display: none;
    }
  }

  &:last-child:after {
    display: none;
  }
`;

const Tags: React.FC<TagsProps> = ({ tags }) => (
  <StyledTags>
    {tags.map((tag) => (
      <StyledTag key={tag.Tag}>{tag.Tag}</StyledTag>
    ))}
  </StyledTags>
);

export default Tags;
