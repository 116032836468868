import React from 'react';
import styled from 'styled-components';
import Lottie from 'lottie-react';
import { scroller } from '$assets/lottie';

const StyledScroller = styled.div`
  position: relative;
  grid-column: 1;
  grid-row: 1 / 3;
  display: none;
  align-self: center;
  padding-bottom: 100%;
  border-radius: 99em;
  background-color: var(--color-white);
  box-shadow: var(--soft-elevation);

  @media screen and (min-width: 769px) {
    display: block;
  }
`;

const StyledScrollerInner = styled(Lottie)`
  position: absolute;
  width: 2em;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
`;

const Tags: React.FC = () => (
  <StyledScroller>
    <StyledScrollerInner animationData={scroller} />
  </StyledScroller>
);

export default Tags;
