import React from 'react';
import styled from 'styled-components';
import scalingSize from '$utils/scalingSize';
import { WrapMax } from '$components/Wraps';

const StyledExcerptWrap = styled(WrapMax)`
  display: grid;
  grid-gap: 1em 3.5em;
  ${scalingSize('margin-top', 16, 144)};
  ${scalingSize('margin-bottom', 32, 112)};

  @media screen and (min-width: 680px) {
    grid-template-columns: auto 8em;
  }

  @media screen and (min-width: 769px) {
    grid-template-columns: 8em auto 8em;
  }
`;

const ExcerptWrap: React.FC = ({ children }) => <StyledExcerptWrap>{children}</StyledExcerptWrap>;

export default ExcerptWrap;
